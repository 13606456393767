<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">用户名称 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.username"
                  placeholder="请输入用户名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">联系电话 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.phone"
                  placeholder="请输入联系电话"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="11"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">区域名称 : </span>
              <div class="width250">
                <el-input
                  v-model="searchData.regionName"
                  placeholder="请输入区域名称"
                  @keyup.enter.native="handleSearch"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10">状态 : </span>
              <div class="width200">
                <el-select
                  class="width250"
                  v-model="searchData.state"
                  clearable
                  @change="handleSearch"
                >
                  <el-option value="" label="请选择"></el-option>
                  <el-option :value="1" label="启用"></el-option>
                  <el-option :value="2" label="禁用"></el-option>
                </el-select>
              </div>
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                >查询</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="info"
                icon="el-icon-refresh-right"
                @click="handleReset"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button
                size="medium"
                type="primary"
                icon="el-icon-plus"
                @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" @click="handleResetAccest(row)"
          >重置密码</el-button
        >
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button type="text" size="mini" @click="handleDelete(row)"
          >删除</el-button
        >
        <el-button type="text" size="mini" @click="handleStateUpdate(row)">{{
          row.state === 1 ? "禁用" : row.state === 2 ? "启用" : ""
        }}</el-button>
      </template>
    </avue-crud>

    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '编辑' : '新增'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入"
            type="text"
            clearable
            maxlength="11"
          />
        </template>

        <template slot="username">
          <el-input
            v-model="form.username"
            placeholder="请输入"
            type="text"
            clearable
          />
        </template>

        <template slot="regionId">
          <el-select
            v-model="form.regionId"
            placeholder="请选择区域"
            @change="getcarTypeListHandle"
          >
            <el-option
              v-for="item in carTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>

        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
      v-if="dialogVisiblePassword"
      :title="`${this.editId ? '编辑' : '新增'}`"
      :visible.sync="dialogVisiblePassword"
      width="40%"
      :before-close="handleClosePassword"
    >
      <div class="password-box">
        <div>
          <i class="el-icon-warning icon"></i>
          <div class="password-content">
            <div class="title">
              {{
                this.editId
                  ? "密码修改成功，请妥善保存账户密码"
                  : "您的新密码已经生成，请妥善保存账户密码"
              }}
            </div>

            <div class="password-copy">
              <span class="marginRight20">账户：{{ accountData.phone }}</span>
              <span class="marginRight20"
                >密码：{{ accountData.password }}</span
              >
              <span @click="handleCopy(accountData)">点此复制</span>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblePassword = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisiblePassword = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  add,
  deleteNew,
  list,
  update,
  view,
  getcarTypeList,
  passwordReset,
  stateUpdate,
} from "@/api/region/white";
export default {
  name: "index",
  data() {
    return {
      searchData: {},
      page: {
        total: 0,
        currentPage: 1,
      },
      tableData: [],
      showLoading: false,
      option: {
        title: "区域白名单设置",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "区域编号",
            prop: "code",
          },
          {
            label: " 区域名称",
            prop: "name",
          },
          {
            label: "用户名称",
            prop: "username",
          },
          {
            label: "联系电话",
            prop: "phone",
          },
          {
            label: "状态",
            prop: "state",
            formatter: function (row, column, cellValue) {
              return row.state == 1 ? "启用" : row.state == 2 ? "禁用" : "";
            },
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
        ],
      },
      carTypeList: [],
      dialogVisible: false,
      dialogVisiblePassword: false,
      form: {
        id: "",
        type: "",
        name: "",
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "160",
        column: [
          {
            label: "登录账号（手机号）",
            prop: "phone",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入手机号",
                trigger: "blur",
              },
              {
                validator: (rule, value, callback) => {
                  if (value === "") {
                    callback(new Error("请输入手机号"));
                  } else {
                    const isPhone = /^1[3456789]\d{9}$/;
                    if (isPhone.test(value)) {
                      callback();
                    } else {
                      callback(new Error("请输入正确的手机号"));
                    }
                  }
                },
              },
            ],
          },
          {
            label: "用户名称",
            prop: "username",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入用户名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "区域",
            prop: "regionId",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择区域",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      editId: false,
      accountData: {
        phone: "",
        password: "",
      },
    };
  },
  created() {
    const searchForm = JSON.parse(localStorage.getItem("searchForm"));
    if (searchForm?.path === this.$route.path) {
      this.searchData = searchForm.searchData;
    } else {
      localStorage.removeItem("searchForm");
    }
    this.getcarTypeListHandle();
  },
  methods: {
    handleCopy(info) {
      // js复制info到剪切板
      const text = `账户：${info.phone} 密码：${info.password}`;
      const input = document.createElement("input");
      input.setAttribute("readonly", "readonly");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message.success("账户密码已复制");
      }
      document.body.removeChild(input);
    },
    getcarTypeListHandle() {
      getcarTypeList().then((res) => {
        if (res.code === 200) {
          this.carTypeList = res.data;
        }
      });
    },
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      localStorage.setItem("searchForm", JSON.stringify(searchForm));
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      // console.log('添加')
      this.dialogVisible = true;
    },
    handleCreatePassword() {
      this.dialogVisiblePassword = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      localStorage.removeItem("searchForm");
      this.searchData = {
        type: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 编辑
    async handleEdit(row) {
      // console.log('编辑',row)
      const { data } = await view({
        id: row.id,
      });
      // console.log(data)
      this.form = data;
      this.dialogVisible = true;
    },
    handleResetAccest(row) {
      this.editId = true;
      this.$confirm(`确认重置用户${row.username}的密码?`, "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        passwordReset({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.dialogVisiblePassword = true;
            this.accountData = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    handleStateUpdate(row) {
      const state = row.state == 1 ? "禁用" : row.state == 2 ? "启用" : "";
      this.$confirm(`确定${state}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        stateUpdate({
          id: row.id,
          state: row.state == 1 ? 2 : 1,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success(`${state}成功!`);
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // 提交
    submit(form, done) {
      // console.log(form)
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("编辑成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      } else {
        add(form)
          .then((res) => {
            if (res.code === 200) {
              this.dialogVisible = false;
              this.dialogVisiblePassword = true;
              this.accountData = res.data;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.error(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    handleClosePassword() {
      this.dialogVisiblePassword = false;
      this.editId = false;
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
        name: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.password-box {
  background: #e6f7ff;
  border: 2px solid #02a7f0;
  padding: 20px;
  position: relative;

  .icon {
    color: #faad14;
    position: absolute;
    left: 20px;
    top: 24px;
    font-size: 24px;
  }
  .password-content {
    padding: 0 40px;

    .title {
      font-size: 20px;
      margin-bottom: 20px;
    }
  }

  .password-copy {
    font-size: 16px;
  }
}
</style>