import { get, post, put, Delete } from "@/api/axios";

// 列表
export const list = params => get('/web/region/white/list', params);

// 区域列表
export const getcarTypeList = params => get('/web/region/white/region/list', params);

// 添加
export const add = params => post('/web/region/white/add', params);

// 修改
export const update = params => put('/web/region/white/update', params);

// 删除
export const deleteNew = params => Delete('/web/region/white/delete/' + params.id, '');

// 查看
export const view = params => get('/web/region/white/view/' + params.id, '');

// 重置密码
export const passwordReset = params => get('/web/region/white/password/reset/' + params.id, '');

// 修改
export const stateUpdate = params => put('/web/region/white/state/update', params);

